import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OddsSidebarCard from './OddsSidebarCard';
import OddsSidebarBetPanel from './OddsSidebarBetPanel';
import styles from './css/OddsSidebar.module.css';

function OddsSidebar({ teams, odds, teamMap }) {
    const selectedOdds = useSelector(state => state.odds.selectedOdds);
    const [storedCards, setStoredCards] = useState(() => {
        const savedCards = localStorage.getItem('selectedCards');
        return savedCards ? JSON.parse(savedCards) : [];
    });
    const [combinedOdds, setCombinedOdds] = useState('Unavailable');
    const [allCsgo, setAllCsgo] = useState(false);

    useEffect(() => {
        if (selectedOdds.length === 0) {
            setStoredCards([]);
            setCombinedOdds('Unavailable');
            localStorage.removeItem('selectedCards');
            return;
        }

        const updatedCards = selectedOdds.map(selectedId => {
            const [matchId, marketIdPart, selectionId] = selectedId.split('_');
            const marketId = `${matchId}_${marketIdPart}`;

            const oddsInfo = odds.find(oddsInfo => {
                return oddsInfo.id === marketId && oddsInfo.selections.some(selection => selection.id === selectedId);
            });



            if (oddsInfo) {
                const selection = oddsInfo.selections.find(selection => selection.id === selectedId);
                if (selection) {
                    const { decimal_odd: decimalOdd, option, game_type, sgp_key, sgp_value } = selection;
                    const { helper_text: helperText, identifier } = oddsInfo;
                    const { answer_type: market, stat } = identifier;

                    let naive_prob;

                    if (oddsInfo.helper_text.startsWith("Will there be an overtime")) {
                        naive_prob = (option === "yes") ? 0.1 : 0.9;
                    } else {
                        naive_prob = (option === "yes" || option === "no" || teamMap[option] !== undefined) ? 0.5 : parseFloat(1 / oddsInfo.num_selections);
                    }
                    const playerID = oddsInfo.identifier.playerID || null;
                    const optionID = teamMap[option] !== undefined ? teamMap[option] : (option === "yes" ? 1 : option === "no" ? 0 : null);

                    return { selectedId, decimalOdd, option, helperText, optionID, market, naive_prob, game_type, playerID, stat, sgp_key, sgp_value };
                }
            }

            return null;
        }).filter(card => card !== null);

        setStoredCards(updatedCards);
        localStorage.setItem('selectedCards', JSON.stringify(updatedCards));

        const allCsgoCheck = updatedCards.every(card => card.game_type === 'csgo');
        setAllCsgo(allCsgoCheck);

        if (updatedCards.length > 0) {
            fetchProbabilities(updatedCards, allCsgoCheck);
        } else {
            setCombinedOdds('Unavailable'); // Reset combinedOdds here
        }
    }, [selectedOdds, odds]);

    const fetchProbabilities = async (updatedCards, allCsgoCheck) => {
        const queryParams = updatedCards.map(card => {
            if (card.sgp_key !== null && card.sgp_value !== null) {
                return `${card.sgp_key}=${card.sgp_value}`;
            }
            return null;
        }).filter(param => param !== null);

        const queryString = queryParams.join('&');
        console.log("Query String:", queryString);
        const totalDecimalOdds = updatedCards.reduce((acc, card) => acc * card.decimalOdd, 1).toFixed(2);


        console.log(updatedCards, updatedCards.length);
        if (queryString) {
            try {
                const response = await fetch(`https://rimbleanalytics.com/probabilities/csgo?${queryString}`, {
                    method: 'GET',
                    headers: {'x-api-key': process.env.REACT_APP_FTP_WEBSITE_KEY},
                    redirect: 'follow'

                });
                const data = await response.json();
                console.log("Probabilities Data:", data);

                if (data.probability !== undefined && data.naive_independent_probability !== undefined) {
                    console.log('correlation factor:', data.naive_independent_probability, data.probability, data.naive_independent_probability / data.probability)
                    const calcOdds = (allCsgoCheck ? (totalDecimalOdds * (data.naive_independent_probability / data.probability)) : totalDecimalOdds);
                    const combinedOdds = parseFloat(calcOdds).toFixed(2);
                    setCombinedOdds(combinedOdds);
                } else {
                    setCombinedOdds(totalDecimalOdds);
                }
            } catch (error) {
                console.error("Error fetching probabilities:", error);
                setCombinedOdds(totalDecimalOdds);
            }
        } else {
            setCombinedOdds(totalDecimalOdds);
        }
    };

    if (!odds || odds.length === 0) {
        return (
            <div className={styles.sidebar}>
                <h2>Selected Bets</h2>
                <div className={styles.loading}>Loading...</div>
            </div>
        );
    }

    return (
        <div className={styles.sidebar}>
            <h2 className={styles.selectedBetsHeader}>Selected Bets</h2>
            <ul className={styles.oddsList}>
                {storedCards.map(card => (
                    <OddsSidebarCard
                        key={card.selectedId}
                        helperText={card.helperText}
                        option={card.option}
                        decimalOdd={card.decimalOdd}
                    />
                ))}
            </ul>
            <p className={styles.combinedOdds}>Combined Odds: {combinedOdds}</p>
            <div className={styles.betPanelContainer}>
                <OddsSidebarBetPanel combinedOdds={combinedOdds} />
            </div>
        </div>
    );
}

export default OddsSidebar;
