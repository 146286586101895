import styles from "./css/GamePage.module.css";
import React, { useState, useEffect, useCallback, useRef, memo } from "react";
// import PopupQuestion from "../components/Stream/PopupQuestion";
import VerticalNav from "../components/Stream/VerticalNav";
import TwitchStream from "../components/Stream/TwitchStream";
// import GameSidebar from "../components/Stream/GameSidebar";
// import BottomQuestion from "../components/Stream/BottomQuestion";
// import BottomAnswer from "../components/Stream/BottomAnswer";
import { useParams } from "react-router";
import OddsGameSidebar from "../components/Stream/OddsGameSidebar";

const MemoizedOddsGameSidebar = memo(OddsGameSidebar);

const demoQuestion1 = {
  market_name: "question_yes_or_no",
  selection_type: "2way",
  outcomes: [
    {
      outcome: "yes",
      probability: 0.5,
      decimal_odd: 1.7857142857142856,
    },
    {
      outcome: "no",
      probability: 0.5,
      decimal_odd: 1.7857142857142856,
    },
  ],
  helper_text: "In round 14, will the bomb be defused?",
  line_rating: 0,
  identifier: {
    answer_type: "answer_round_history",
    stat: "spike_defused",
    market_type: "round_spike_defused",
    stat_value: null,
    team_specific_stat: null,
    map_specific_stat: 1,
    player_specific_stat: null,
    round_specific_stat: 14,
    id: 0,
  },
};

const demoQuestion2 = {
  market_name: "question_yes_or_no",
  selection_type: "2way",
  outcomes: [
    {
      outcome: "yes",
      probability: 0.5,
      decimal_odd: 1.7857142857142856,
    },
    {
      outcome: "no",
      probability: 0.5,
      decimal_odd: 1.7857142857142856,
    },
  ],
  helper_text: "In round 17, will the spike be defused?",
  line_rating: 0,
  identifier: {
    answer_type: "answer_round_history",
    stat: "spike_defused",
    market_type: "round_spike_defused",
    stat_value: null,
    team_specific_stat: null,
    map_specific_stat: 1,
    player_specific_stat: null,
    round_specific_stat: 17,
    id: 1,
  },
};

const demoPersistentQuestion = {
  market_name: "question_team_stats",
  selection_type: "2way",
  outcomes: [
    {
      outcome: "team_1",
      team_name: "Test1",
      probability: 0.7650131645109381,
      decimal_odd: 1.2121018706323223,
    },
    {
      outcome: "team_2",
      team_name: "Test2",
      probability: 0.23498683548906185,
      decimal_odd: 3.389981787973993,
    },
  ],
  helper_text: "In game 1, which team will win the map?",
  line_rating: 0,
  identifier: {
    answer_type: "answer_team_metadata_max",
    stat: "current_score",
    market_type: "map_winner",
    stat_value: null,
    team_specific_stat: null,
    map_specific_stat: 1,
    player_specific_stat: null,
    round_specific_stat: null,
    id: 2,
  },
};

const demoAnswer = {
  market_name: "question_yes_or_no",
  selection_type: "2way",
  outcomes: [
    {
      outcome: "yes",
      probability: 0.5,
      decimal_odd: 1.7857142857142856,
    },
    {
      outcome: "no",
      probability: 0.5,
      decimal_odd: 1.7857142857142856,
    },
  ],
  helper_text: "In round 14, will the spike be defused?",
  line_rating: 0,
  settled: true,
  result: {
    outcome: "no",
    probability: 0.5,
    decimal_odd: 1.7857142857142856,
  },
  identifier: {
    answer_type: "answer_round_history",
    stat: "spike_defused",
    market_type: "round_spike_defused",
    stat_value: null,
    team_specific_stat: null,
    map_specific_stat: 1,
    player_specific_stat: null,
    round_specific_stat: 14,
    id: 0,
  },
};
/*
const demoAnswer2 = {
  market_name: "question_yes_or_no",
  selection_type: "2way",
  outcomes: [
    {
      outcome: "yes",
      probability: 0.5,
      decimal_odd: 1.7857142857142856,
    },
    {
      outcome: "no",
      probability: 0.5,
      decimal_odd: 1.7857142857142856,
    },
  ],
  helper_text: "In round 17, will the spike be defused?",
  line_rating: 0,
  settled: true,
  result: {
    outcome: "no",
    probability: 0.5,
    decimal_odd: 1.7857142857142856,
  },
  identifier: {
    answer_type: "answer_round_history",
    stat: "spike_defused",
    market_type: "round_spike_defused",
    stat_value: null,
    team_specific_stat: null,
    map_specific_stat: 1,
    player_specific_stat: null,
    round_specific_stat: 17,
    id: 1,
  },
};
*/
function OddsPage(props) {
  const videoRef = useRef(null);
  const { gameid } = useParams();
  // const userid = "testwebsitereactuser";
  // const matchid = "999999999";
  // const [freshpage, setFreshpage] = useState(true);
  const [twitchChannel, setTwitchChannel] = useState("");
  const [videoReady, setVideoReady] = useState(false);

  const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  const [persistentQuestions, setPersistentQuestions] = useState({});
  const [answers, setAnswers] = useState([]);
  const [askedQuestions, setAskedQuestions] = useState({});

  const questionAnswered = useCallback(
    (answerid, questionId) => {
      // console.log("answerid selected ", answerid, "questionId", questionId);
      if (questionId in askedQuestions) {
        return;
      }

      var updatedAskedQuestions = {};
      if (
        unansweredQuestions.some((item) => item.identifier.id === questionId)
      ) {
        updatedAskedQuestions = {
          ...askedQuestions,
          [questionId]: {
            answerid: answerid,
            question: unansweredQuestions.find(
              (item) => item.identifier.id === questionId
            ),
          },
        };
      } else {
        updatedAskedQuestions = {
          ...askedQuestions,
          [questionId]: {
            answerid: answerid,
            question: persistentQuestions[questionId],
          },
        };
      }
      setAskedQuestions(updatedAskedQuestions);
    },
    [askedQuestions, persistentQuestions, unansweredQuestions]
  );

  const addPersistentQuestions = useCallback((questions) => {
    if (questions.length === 0) {
      return;
    }
    const updatedPersistentQuestions = { ...persistentQuestions };
    questions.forEach((q) => {
      updatedPersistentQuestions[q.identifier.id] = q;
    });

    setPersistentQuestions(updatedPersistentQuestions);
  }, [persistentQuestions]);

  const addUnansweredQuestion = useCallback((question) => {
    // game_num = question.question_metadata.game_num
    if (question == null) {
      return null;
    }
    // generate random number from 1.1 - 3.0 for odds of answer 1 and answer 2
    const answer_1_odd = (Math.floor(Math.random() * (30 - 11 + 1)) + 11) / 10;
    const answer_2_odd = (Math.floor(Math.random() * (30 - 11 + 1)) + 11) / 10;
    question.outcomes[0].decimal_odd = answer_1_odd;
    question.outcomes[1].decimal_odd = answer_2_odd;

    // error with questionId not getting updated since it's called in .onmessage(). Fixed it by using length
    // const question_id = question["identifier"]["id"];
    if (
      !unansweredQuestions.some(
        (item) => item.identifier.id === question.identifier.id
      )
    ) {
      setUnansweredQuestions((prevUnansweredQuestions) => [
        ...prevUnansweredQuestions,
        question,
      ]);
    }
  }, [unansweredQuestions]);

  const addAnswers = useCallback((previous_answers) => {
    // Handles the first question sent (no answer yet)
    if (previous_answers.length === 0) {
      return;
    }

    const updatedAnswers = [...answers];
    previous_answers.forEach((answer) => {
      if (
        !updatedAnswers.some(
          (item) => item.identifier.id === answer.identifier.id
        )
      ) {
        updatedAnswers.push(answer);
      }
    });

    setAnswers(updatedAnswers);
  }, [answers]);

  useEffect(() => {
    if (videoReady && videoRef.current) {
      videoRef.current.play();
    }
  }, [videoRef, videoReady]);

  useEffect(() => {
    // setShowQuestionWindow(true);

    const url = "wss://rimbleliveanalytics.com/odds-valorant";

    const fetchTwitchLink = async () => {
        if (gameid === "demo") {
            setTwitchChannel("https://www.twitch.tv/valorant_pacific".split("/").pop());
            setVideoReady(true);
            return;
        }

        try {
            const game_data = await fetch(
                "https://rimbleanalytics.com/predictions/valorant/match-status?matchid=" + gameid,
                {
                    method: "GET",
                    headers: { "x-api-key": process.env.REACT_APP_FTP_WEBSITE_KEY },
                }
            );

            const game_data_json = await game_data.json();
            if (game_data_json[0].stream_links && game_data_json[0].stream_links.length) {
                setTwitchChannel(game_data_json[0].stream_links[0].split("/").pop());
                // setVideoReady(true);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
        // console.log(game_data_json[0])
    };
    fetchTwitchLink();

    // Connect to Valorant WebSocket QA
    const valorant_qa_websocket = new WebSocket(url);
    valorant_qa_websocket.onopen = () => {
      console.log("WebSocket connection established.");
    };

    valorant_qa_websocket.onmessage = (event) => {
      console.log("Received message from Valorant websocket:", event.data);
      var qa_response = JSON.parse(event.data);
      // if (true) {
      // Only add question if the question's matchid == gameid
      if (qa_response.body[0].matchid === gameid) {
        addUnansweredQuestion(qa_response.body[0].current_odds);
        addPersistentQuestions(qa_response.body[0].persistent_odds);
        addAnswers(qa_response.body[0].previous_odds_answers);
      }
    };

    valorant_qa_websocket.onclose = () => {
      console.log("WebSocket connection closed.");
      // Handle the closed connection
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      valorant_qa_websocket.close();
    };
  }, [addAnswers, addPersistentQuestions, addUnansweredQuestion, gameid]);

  useEffect(() => {
    if (gameid === "demo" && Object.keys(unansweredQuestions).length === 0) {
      addUnansweredQuestion(demoQuestion1);
      addPersistentQuestions([demoPersistentQuestion]);
      setTimeout(() => {
        addUnansweredQuestion(demoQuestion2);
        addAnswers([demoAnswer]);
      }, 120000);
      // setTimeout(() => {
      //   addAnswers([demoAnswer2])
      // }, 25000)
    }
  }, [addAnswers, addPersistentQuestions, addUnansweredQuestion, gameid, unansweredQuestions]);

  useEffect(() => {
    // setShowQuestionWindow(true);

    const url = "wss://rimbleliveanalytics.com/odds-lol";

    const fetchTwitchLink = async () => {
        if (gameid === "demo") {
            setTwitchChannel("https://www.twitch.tv/".split("/").pop());
            setVideoReady(true);
            return;
        }

        try {
            const game_data = await fetch(
                "https://rimbleanalytics.com/predictions/lol/match-status?matchid=" + gameid,
                {
                    method: "GET",
                    headers: { "x-api-key": process.env.REACT_APP_FTP_WEBSITE_KEY },
                }
            );

            const game_data_json = await game_data.json();
            if (game_data_json[0].stream_links && game_data_json[0].stream_links.length) {
                setTwitchChannel(game_data_json[0].stream_links[0].split("/").pop());
                setVideoReady(true);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
        // console.log(game_data_json[0])
    };
    fetchTwitchLink();

    // Connect to LoL WebSocket QA
    const lol_qa_websocket = new WebSocket(url);
    lol_qa_websocket.onopen = () => {
      console.log("WebSocket connection established.");
    };

    lol_qa_websocket.onmessage = (event) => {
      console.log("Received message from LOL websocket:", event.data);
      var qa_response = JSON.parse(event.data);
      // if (true) {
      // Only add question if the question's matchid == gameid
      if (qa_response.body[0].matchid === gameid) {
        addUnansweredQuestion(qa_response.body[0].current_odds);
        addPersistentQuestions(qa_response.body[0].persistent_odds);
        addAnswers(qa_response.body[0].previous_odds_answers);
      }
    };

    lol_qa_websocket.onclose = () => {
      console.log("WebSocket connection closed.");
      // Handle the closed connection
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      lol_qa_websocket.close();
    };
  }, [addAnswers, addPersistentQuestions, addUnansweredQuestion, gameid]);

  useEffect(() => {
    if (gameid === "demo" && Object.keys(unansweredQuestions).length === 0) {
      addUnansweredQuestion(demoQuestion1);
      addPersistentQuestions([demoPersistentQuestion]);
      setTimeout(() => {
        addUnansweredQuestion(demoQuestion2);
        addAnswers([demoAnswer]);
      }, 120000);
      // setTimeout(() => {
      //   addAnswers([demoAnswer2])
      // }, 25000)
    }
  }, [addAnswers, addPersistentQuestions, addUnansweredQuestion, gameid, unansweredQuestions]);


  useEffect(() => {
    // setShowQuestionWindow(true);

    const url = "wss://rimbleliveanalytics.com/odds-csgo";

    const fetchTwitchLink = async () => {
        if (gameid === "demo") {
            setTwitchChannel("https://www.twitch.tv/".split("/").pop());
            setVideoReady(true);
            return;
        }

        try {
            const game_data = await fetch(
                "https://rimbleanalytics.com/predictions/csgo/match-status?matchid=" + gameid,
                {
                    method: "GET",
                    headers: { "x-api-key": process.env.REACT_APP_FTP_WEBSITE_KEY },
                }
            );

            const game_data_json = await game_data.json();
            if (game_data_json[0].stream_links && game_data_json[0].stream_links.length) {
                setTwitchChannel(game_data_json[0].stream_links[0].split("/").pop());
                setVideoReady(true);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
        // console.log(game_data_json[0])
    };
    fetchTwitchLink();

    // Connect to CSGO WebSocket QA
    const csgo_qa_websocket = new WebSocket(url);
    csgo_qa_websocket.onopen = () => {
      console.log("WebSocket connection established.");
    };

    csgo_qa_websocket.onmessage = (event) => {
      console.log("Received message from CSGO Websocket:", event.data);
      var qa_response = JSON.parse(event.data);
      // if (true) {
      // Only add question if the question's matchid == gameid
      if (qa_response.body[0].matchid === gameid) {
        addUnansweredQuestion(qa_response.body[0].current_odds);
        addPersistentQuestions(qa_response.body[0].persistent_odds);
        addAnswers(qa_response.body[0].previous_odds_answers);
      }
    };

    csgo_qa_websocket.onclose = () => {
      console.log("WebSocket connection closed.");
      // Handle the closed connection
    };

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      csgo_qa_websocket.close();
    };
  }, [addAnswers, addPersistentQuestions, addUnansweredQuestion, gameid]);

  useEffect(() => {
    if (gameid === "demo" && Object.keys(unansweredQuestions).length === 0) {
      addUnansweredQuestion(demoQuestion1);
      addPersistentQuestions([demoPersistentQuestion]);
      setTimeout(() => {
        addUnansweredQuestion(demoQuestion2);
        addAnswers([demoAnswer]);
      }, 120000);
      // setTimeout(() => {
      //   addAnswers([demoAnswer2])
      // }, 25000)
    }
  }, [addAnswers, addPersistentQuestions, addUnansweredQuestion, gameid, unansweredQuestions]);

  return (
    <div className={styles.gameContainer}>
      <div className={styles.contentContainer}>
        <VerticalNav />
        <div className={styles.middleContent}>
          {twitchChannel ? (
            <>
              <TwitchStream channel={twitchChannel} />
              <div data-vjs-player>
                <video ref={videoRef} className="video-js"></video>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className={styles.popUpQuestion}></div>
        </div>
          <MemoizedOddsGameSidebar
            chatChannel={twitchChannel}
            flashOdds={unansweredQuestions}
            persistentOdds={persistentQuestions}
            answeredQuestions={askedQuestions}
            questionAnswered={questionAnswered}
            answers={answers}
          />
      </div>
    </div>
  );
}

export default OddsPage;
