import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import Footer from '../components/Global/Footer';
import royalTeeImage from '../assets/royale.png';
import rimbleTeeImage from '../assets/rimble.png';
import mouse from '../assets/mouse.png';
import keyboard from '../assets/keyboard.png';


const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

const products = [
  { id: 1, name: 'Rimble / Keyboard', price: 65, image: keyboard },
  { id: 2, name: 'Rimble / Mouse', price: 65, image: mouse},
  { id: 3, name: 'Rimble / Tshirt Tee', price: 35, image: rimbleTeeImage },
  { id: 4, name: 'Rimble / Royal Tee', price: 35, image: royalTeeImage },
];

const ShoppingPage = () => {
  const [cart, setCart] = useState([]);
  const [checkout, setCheckout] = useState(false);

  const addToCart = (product) => {
    setCart([...cart, product]);
  };

  const handleCheckout = () => {
    setCheckout(true);
  };

  const total = cart.reduce((sum, item) => sum + item.price, 0);

  return (
    <>
      <div style={{ padding: '20px', backgroundColor: '#0D1B24', color: '#fff', minHeight: '100vh' }}>
        {!checkout ? (
          <>
            <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>All Products</h1>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '20px' }}>
              {products.map((product) => (
                <div key={product.id} style={{ textAlign: 'center', backgroundColor: '#1A2730', color: '#fff', padding: '10px', borderRadius: '5px' }}>
                  <img
                    src={product.image}
                    alt={product.name}
                    style={{ maxWidth: '100%', height: 'auto', borderRadius: '5px' }}
                  />
                  <h3>{product.name}</h3>
                  <p>${product.price.toFixed(2)} USD</p>
                  <button
                    onClick={() => addToCart(product)}
                    style={{
                      padding: '10px',
                      backgroundColor: '#00FFB2',
                      color: '#0D1B24',
                      border: 'none',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      fontWeight: 'bold'
                    }}
                  >
                    Add to Cart
                  </button>
                </div>
              ))}
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <h2>Total: ${total.toFixed(2)} USD</h2>
              <button
                onClick={handleCheckout}
                style={{
                  padding: '15px 30px',
                  backgroundColor: '#B341FF',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
              >
                Proceed to Checkout
              </button>
            </div>
          </>
        ) : (
          <Elements stripe={stripePromise}>
            <CheckoutForm cart={cart} total={total} />
          </Elements>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ShoppingPage;
