import React, { useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';

const CheckoutForm = ({ cart, total }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
      <h2 style={{ color: '#fff', marginBottom: '20px' }}>Checkout</h2>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <label style={{ color: '#fff' }}>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            style={{
              padding: '12px',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: '#1A2730',
              color: '#fff'
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <label style={{ color: '#fff' }}>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            style={{
              padding: '12px',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: '#1A2730',
              color: '#fff'
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <label style={{ color: '#fff' }}>Address:</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
            style={{
              padding: '12px',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: '#1A2730',
              color: '#fff'
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <label style={{ color: '#fff' }}>Card Details:</label>
          <div style={{
            padding: '12px',
            borderRadius: '5px',
            backgroundColor: '#1A2730',
            color: '#fff'
          }}>
            <CardElement options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#fff',
                  '::placeholder': {
                    color: '#aab7c4'
                  }
                }
              }
            }} />
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <h3 style={{ color: '#fff', marginBottom: '10px' }}>Order Summary</h3>
          {cart.map((item, index) => (
            <div key={index} style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#fff',
              marginBottom: '8px'
            }}>
              <span>{item.name}</span>
              <span>${item.price.toFixed(2)}</span>
            </div>
          ))}
          <div style={{
            borderTop: '1px solid #1A2730',
            marginTop: '10px',
            paddingTop: '10px',
            color: '#fff',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <strong>Total:</strong>
            <strong>${total.toFixed(2)}</strong>
          </div>
        </div>
        <button
          type="submit"
          style={{
            padding: '15px',
            backgroundColor: '#00FFB2',
            color: '#0D1B24',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: 'bold',
            marginTop: '20px'
          }}
        >
          Pay ${total.toFixed(2)}
        </button>
      </form>
    </div>
  );
};

export default CheckoutForm;
