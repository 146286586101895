import Modal from './ModalWrapper.js';
import styles from "./css/Nav.module.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react'
import Login from '../Auth/Login';
import Register from '../Auth/Register';
import rimbleLogo from '../../assets/rimbleLogo.svg';
import {RiYoutubeFill, RiTwitchFill, RiDiscordFill} from "react-icons/ri";
import { Auth } from 'aws-amplify';

function Nav(props) {
    const [registerOpen, setRegisterOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [username, setUsername] = useState("");

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(data => {
            setUsername(data.attributes.email);
        }).catch(err => {
            console.log("Auth error: ", err)
        });
    })

    function signOut() {
        Auth.signOut().then(data => {
            setUsername("")
            console.log("Signed out!")
        }).catch(err => console.log("Sign out failed: ", err))
    }

    const { pathname } = props.location;
    const containerClass = pathname !== "/" ? `${styles.navContainer} ${styles.dark}` : styles.navContainer;
    return (
        <div className={containerClass}>
            <div className={styles.navContent}>
                <div className={styles.leftContainer}>
                    <Link  to="/">
                        <img
                        src={rimbleLogo}
                        className={styles.rimbleLogo}
                        alt="website logo"
                        />
                    </Link>
                    <div className={styles.navList}>
                        <Link to="/how">How to Play</Link>
                        <Link to="/play">Play Now</Link>
                        <Link to="/shop">Store</Link>
                    </div>
                </div>

                <div className={styles.rightContainer}>
                    <Link to="/" >
                        <RiYoutubeFill className={styles.mediaLogo}/>
                    </Link>
                    <Link to="/">
                        <RiDiscordFill className={styles.mediaLogo}/>
                    </Link>
                    <Link to="/">
                        <RiTwitchFill className={styles.mediaLogo}/>
                    </Link>
                    { username.length === 0 && <Link onClick={() => !loginOpen && setRegisterOpen(true)} className={styles.register} to="/">Register</Link>}
                    { username.length === 0 && <Link onClick={() => !registerOpen && setLoginOpen(true)} className={styles.signIn} to="/">Sign In</Link>}
                    { username.length !== 0 && <Link className={styles.username} to="/profile">{username}</Link>}
                    { username.length !== 0 && <p onClick={signOut}>Sign out</p>}
                </div>
            </div>
            <Modal isOpen={registerOpen} toggleFn={setRegisterOpen}>
                <Register setRegisterOpen={setRegisterOpen} />
            </Modal>
            <Modal isOpen={loginOpen} toggleFn={setLoginOpen}>
                <Login setLoginOpen={setLoginOpen} />
            </Modal>
        </div>
    );
}

export default Nav;
