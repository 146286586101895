import styles from "./css/HomeHeader.module.css";
import homeFortnite from '../../assets/homeFortnite.png';
import { Link } from 'react-router-dom';
import TextOverlay from "../Global/TextOverlay";

function HomeHeader() {
    return (
        <div className={styles.headerContainer}>
            <div className={styles.topContainer}>
                <hr className={styles.lineOne}/>
                <hr className={styles.lineTwo}/>
                <TextOverlay textClass={styles.titleText}>COME GAME WITH US</TextOverlay>
                <p className={styles.subtitle}>Experience watching, predicting, and winning esports in real time.</p>
                <Link to="/play" className={styles.streamBtn}>STREAMS</Link>
            </div>

            <div className={styles.bottomContainer}>
                <img src={homeFortnite} className={styles.fortniteImg}/>
                <div className={styles.bottomRightContainer}>
                    <p className={styles.description}>Rimble is where esport fans gather together every day to chat, interact, predict, and make their own adventures. Watch, game, and predict with us.</p>
                    <Link to="/how" className={styles.streamBtn}>LEARN NOW</Link>
                </div>
            </div>
        </div>
    );
}

export default HomeHeader;
